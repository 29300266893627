<template>
  <div class="layout-menu-container">
    <the-panel-submenu
      :items="model"
      class="layout-menu"
      :root="true"
      @menuitem-click="onMenuItemClick"
    />
  </div>
</template>

<script>
import ThePanelSubmenu from "./ThePanelSubmenu";

export default {
  name: "ThePanelMenu",
  components: {
    ThePanelSubmenu,
  },
  props: {
    model: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["menuitem-click"],
  setup(props, { emit }) {
    function onMenuItemClick(event) {
      emit("menuitem-click", event);
    }

    return {
      onMenuItemClick,
    };
  },
};
</script>
